<template>
  <div>
      <v-card>
          <v-card-text>
            <v-text-field v-model="$attrs.value.given_name" label="First Name" outlined/>
            <v-text-field v-model="$attrs.value.family_name" label="Family Name" outlined/>
            <v-textarea v-model="$attrs.value.credentials[language]" label="Credentails" outlined/>
            <v-textarea v-model="$attrs.value.details[language]" label="Details" outlined/>
            <v-textarea v-model="$attrs.value.disclosures[language]" label="Disclosures" outlined/>
            <v-select v-model="$attrs.value.image.type" :items="[{text:'icon', value:'icon'},{text:'image', value:'img'}]" label="Image Type"/>
            <v-text-field label="Image Source" v-model="$attrs.value.image.value" outlined/>
            <hr>
            <v-card>
                <v-card-title>Preview</v-card-title>
                <v-card-text>
                    <SpeakerCard :language="language" :speaker="model"/>
                </v-card-text>
            </v-card>
          </v-card-text>
          <v-card-actions>
            <v-btn text @click="$emit('cancel',model)"><v-icon>mdi-chevron-left</v-icon>Back</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="$emit('save',model)">Save</v-btn>
          </v-card-actions>
      </v-card>

  </div>
</template>

<script>
import SpeakerCard from '../../lib/SpeakerCard.vue'

export default {
    props: {
        language: {
            type: String,
            required: true
        }
    },
    components: {
        SpeakerCard
    },
    created: function(){
        this.init()
    },
    methods: {
        init: async function(){
            let ignore = ['given_name','family_name','active','created','updated','id']
            let speaker = this.model
            let language = this.language
            for(let key in speaker){
                if(!ignore.includes(key)){
                    if(this.$attrs.value[key]==null){
                        this.$attrs.value[key] = {}
                    }

                    if(!this.$attrs.value[key][language]){
                        this.$attrs.value[key][language] = null
                    }
                }
            }
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        }
    }
}
</script>

<style>

</style>