<template>
<div>
    <v-container fluid>
        <v-row>
            <v-col sm="12" md="6">
                <h3>ZOOM Participants</h3>
                <template v-if="zoomParticipants">
                    <div v-if="!Array.isArray(zoomParticipants)">
                        {{zoomParticipants}}
                    </div>
                    <v-list v-else>
                        <v-card v-for="participant in zoomParticipantMap" :key="'user_id_'+participant.id">
                            <v-list-item dense>
                                <v-list-item-content>
                                    <v-list-item-title>
                                        User Name: {{participant.user_name}}
                                    </v-list-item-title>
                                    <v-list-item-subtitle>
                                        Joined: {{participant.joined}}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        Left: {{participant.left}}
                                    </v-list-item-subtitle>
                                    <v-list-item-subtitle>
                                        In Meeting Minutes: {{participant.in_meeting_minutes}}
                                    </v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-card>
                    </v-list>
                    <!-- <json-viewer :value="zoomParticipantMap"/> -->
                </template>                
            </v-col>
            <v-col sm="12" md="6">
                <h3>Enrolled</h3>
                <template v-if="participants">
                    <div v-for="(participant) in participantsOrdered" :key="participant.user_id">
                        <Participant v-model="participants[participant.participantIndex]" :user="userMap[participant.user_id]" :participant="participant" :zoomParticipantMap="zoomParticipantMap"/>
                    </div>
                </template>                
            </v-col>
        </v-row>
    </v-container>


</div>
</template>

<script>
import Participant from './attendee.vue'
export default {
    props: {
        meeting_id: {
            type: Number,
            required: true
        },
        event_id: {
            type: Number,
            required: true
        }
    },
    components: {
        Participant
    },
    created: function(){
        this.init()
    },
    data(){
        return {
            users: null,
            participants: null,
            zoomParticipants: null
        }
    },
    methods: {
        init: async function(){
            await this.getParticipants()
            await this.getZoomParticipants()
        },
        getParticipants: async function(){
            let self = this
            try {
                let result = await self.sendRequest('get',`/api/event/admin/getParticipants/${self.event_id}`)
                if(result.status==200){
                    self.participants = result.data.participants
                    self.users = result.data.users
                }
            } catch (error) {
                console.error('Error getting participants', error)
            }
            return []
        },
        getZoomParticipants: async function(){
            if (this.$store.getters.zoomIntegrationTyp==='manual'){
                return this.zoomParticipants = "Data not available for Zoom manual integration"
            }
            let self = this
            try {
                let result = await self.sendRequest('get',`/api/zoom/meeting/getParticipants/${self.meeting_id}`)
                if(result.status==200){
                    self.zoomParticipants = result.data.participants
                }
            } catch (error) {
                console.error('Error getting participants', error)
            }
            return []
        }
    },
    computed: {
        userMap: function(){
            let output = {}
            let users = this.users ? this.users : []
            for(let i=0; i<users.length; i++){
                let user = users[i]
                output[user.Username] = {}

                for(let a=0; a<user.Attributes.length; a++){
                    let attribute = user.Attributes[a]
                    output[user.Username][attribute.Name] = attribute.Value
                }
            }

            return output
        },
        zoomParticipantMap: function(){
            let output = {}
            let participants = this.zoomParticipants ? this.zoomParticipants : []

            for(let i=0; i<participants.length; i++){
                let participant = participants[i]
                if(participant.status=='in_meeting' && participant.role!='host'){
                    let t1 = new Date(participant.join_time)
                    let t2 = new Date(participant.leave_time)
                    participant.joined = t1
                    participant.left = t2
                    participant.in_meeting_minutes = (t2-t1)/1000/60
                    output[participant.id] = participant
                }
            }

            return output
        },
        participantsOrdered: function(){
            let output = []

            let participants = this.participants
            let userMap = this.userMap

            for(let i=0; i<participants.length; i++){
                let participant = participants[i]
                participant.participantIndex = i
                participant.given_name = userMap[participant.user_id] !== undefined ? userMap[participant.user_id].given_name : 'unknown'
                participant.family_name = userMap[participant.user_id] !== undefined ? userMap[participant.user_id].family_name : 'unknown'
                output.push(participant)
            }

            output.sort((a, b) => {
                let fa = a.given_name!=null && a.given_name!=undefined && a.given_name.length>0 ? a.given_name.toLowerCase() : '',
                    fb = b.given_name!=null && b.given_name!=undefined && b.given_name.length>0 ? b.given_name.toLowerCase() : ''

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });


            return output
        }
    }
}
</script>

<style>

</style>