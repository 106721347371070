<template>
  <div>
    <Participants v-if="model.zoom && model.zoom.id && model.id" :event_id="model.id" :meeting_id="model.zoom.id"/>
    <v-card>
        <v-card-text>
            <v-select label="Event Language" v-model="$attrs.value.language" :items="languageItems" outlined/>

            <v-container v-if="$attrs.value.language" fluid>
                <v-row>
                    <v-col cols="6">
                        <h2>Start</h2>
                        <v-date-picker v-model="form.start.date" :max="form.end.date"></v-date-picker>
                        <v-time-picker
                        format="ampm"
                        landscape
                        v-model="form.start.time"
                        :max="form.end.time"
                        ></v-time-picker>      
                    </v-col>
                    <v-col cols="6" v-if="form.start.time && form.start.date">
                        <h2>End</h2>
                        <v-date-picker v-model="form.end.date" :min="form.start.date"></v-date-picker>
                        <v-time-picker
                        format="ampm"
                        landscape
                        v-model="form.end.time"
                        :min="form.start.time"
                        ></v-time-picker>
                    </v-col>
                </v-row>
                <v-row v-if="$attrs.value.start_time && $attrs.value.end_time">
                    <v-col style="text-align: center;">
                        <h1>Event Duration: {{duration}}min</h1>
                    </v-col>
                </v-row>
            </v-container>
      
            <template v-if="duration!=null && duration>0">
                <v-card style="margin: 25px;">
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col>
                                    <h3 class="mb-3">Event Details</h3>
                                    <v-text-field v-model="$attrs.value.title[$attrs.value.language]" label="Event Title" outlined/>
                                    <v-textarea label="Introduction" v-model="$attrs.value.introduction[$attrs.value.language]"/>
                                    <v-textarea label="Learning Objectives" v-model="$attrs.value.learningObjectives[$attrs.value.language]"/>
                                    <v-textarea label="Agenda" v-model="$attrs.value.agenda[$attrs.value.language]"/>
                                </v-col>
                                <v-col justify-center>
                                    <template  v-if="$attrs.value.zoom">
                                        <h3 class="mb-3">Zoom Details</h3>
                                        <v-btn icon small fab color="error" @click="unlinkZoom">
                                            <v-icon>
                                                mdi-delete
                                            </v-icon>
                                        </v-btn>
                                        <json-viewer :value="$attrs.value.zoom"/>
                                    </template>
                                    <v-card v-else-if="$store.getters.zoomIntegrationTyp==='manual'" class="pa-3" outlined tile>
                                        <h3 class="mb-3">Zoom Details</h3>
                                        <v-text-field v-model="zoom_id" label="Zoom Event ID (numerical)" outlined/>
                                        <v-text-field v-model="zoom_join_url" label="Zoom Join URL" outlined/>
                                        <v-text-field v-model="zoom_password" label="Zoom Password" outlined/>
                                        <div class="d-flex justify-center">
                                            <v-btn text outlined tile small @click="absorbZoomDetails">Absorb Zoom Details</v-btn>
                                        </div>
                                    </v-card>
                                    <template v-else>
                                        <v-text-field type="number" v-model="zoom_id" label="Zoom Meeting ID"/>
                                        <v-btn text @click="importZoom">Import Zoom Meeting</v-btn>
                                    </template>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <v-switch :true-value="'Y'" :false-value="'N'" v-model="$attrs.value.publish" @change="mutualExclusionPublishCancel($event, 'publish')" label="Publish Event"/>
                                    <v-switch :true-value="'Y'" :false-value="'N'" v-model="$attrs.value.cancelled" @change="mutualExclusionPublishCancel($event, 'cancelled')" label="Cancel Event"/>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>

                    <v-card-actions>
                        <v-btn text @click="$emit('cancel',model)"><v-icon>mdi-chevron-left</v-icon>Back</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn icon color="success" :disabled="busy" @click="$emit('save',model)">
                            <v-icon large v-if="!busy">
                                mdi-content-save
                            </v-icon>
                            <v-icon large v-else>
                                mdi-spin mdi-loading
                            </v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
                                                    
                <v-card v-if="allowSpeakerSelection" style="margin: 25px;">
                    <v-card-title>Speakers</v-card-title>
                    <v-card-text>
                        <h3 v-if="Presenter">
                            First speaker selected will be named as presenter on certificate: {{Presenter.given_name}} {{Presenter.family_name}}
                        </h3>
                        <v-container dense>
                            <template v-for="speaker in speakers">
                                <v-row :key="'speaker_selection_'+speaker.id">
                                    <v-col cols="3">
                                        <SpeakerCard 
                                            style="width: 100%;"
                                            :language="language"
                                            :speaker="speaker"
                                            :class="[{'disabled':!$attrs.value.speakers.includes(speaker.id)}]"
                                        />
                                    </v-col>
                                    <v-col cols="9">
                                        <v-checkbox v-model="$attrs.value.speakers" :value="speaker.id"  :label="speaker.given_name+' '+speaker.family_name"/>
                                    </v-col>
                                </v-row>
                            </template>
                        </v-container>
                    </v-card-text>
                </v-card>
            </template>


        </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SpeakerCard from '../../lib/SpeakerCard.vue'
import Participants from './participants.vue'

export default {
    props: {
        busy: {
            type: Boolean,
            required: false,
            default: false
        },
        speakers: {
            type: Array,
            required: true
        },
        language: {
            type: String,
            required: true
        },
        languages: {
            type: Array,
            required: true
        },
        allowSpeakerSelection: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    components: {
        SpeakerCard,
        Participants
    },
    created: function(){
        this.init()
    },
    data: function(){
        return {
            form: {
                start: {
                    date: null,
                    time: null
                },
                end: {
                    date: null,
                    time: null
                }
            },
            zoom_id: null,
            zoom_join_url: null,
            zoom_password: null
        }
    },
    methods: {
        init: function(){
            function makeDigital(number){
                if(number<10){
                    return "0"+number
                }
                return number
            }

            if(this.$attrs.value.id){
                let form = this.form
                let start = new Date(this.$attrs.value.start_time)
                let end = new Date(this.$attrs.value.end_time)
                form.start.date = start.getFullYear()+'-'+makeDigital(start.getMonth()+1)+'-'+makeDigital(start.getDate())
                form.start.time = start.getHours()+':'+makeDigital(start.getMinutes())
    
                form.end.date = end.getFullYear()+'-'+makeDigital(end.getMonth()+1)+'-'+makeDigital(end.getDate())
                form.end.time = end.getHours()+':'+makeDigital(end.getMinutes())
            }else{
                this.$attrs.value.language = this.language
            }
        },
        unlinkZoom: function(){
            this.zoom_id = null
            if (this.$store.getters.zoomIntegrationTyp==='manual'){
                this.zoom_join_url = null
                this.zoom_password = null
            }
            this.$attrs.value.zoom = null
            this.$forceUpdate();
        },
        importZoom: function(){
            this.$emit('importZoom',{zoom_id:this.zoom_id})
        },
        absorbZoomDetails: function(){
            this.$emit('absorbZoomDetails',{zoom_id: Number(this.zoom_id.split(" ").join("")), zoom_join_url: this.zoom_join_url, zoom_password: this.zoom_password})
        },
        mutualExclusionPublishCancel: function(eventValue, eventSource){
            console.log(eventValue, eventSource)
            
            if (eventSource==='publish' && eventValue==='Y' && this.model.cancelled==='Y'){
                this.model.cancelled = 'N'
            }
            if (eventSource==='cancelled' && eventValue==='Y' && this.model.publish==='Y'){
                this.model.publish = 'N'
            }
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        },
        languageItems: function(){
            let languages = this.languages
            let output = []

            for(let i=0; i<languages.length; i++){
                let language = languages[i]
                output.push({
                    text: language,
                    value: language
                })
            }

            return output
        },
        duration: function(){
            let start = this.form.start
            let end = this.form.end

            if(start.date && start.time && end.date && end.time){
                let t1 = new Date(start.date+' '+start.time)
                let t2 = new Date(end.date+' '+end.time)
                return (t2-t1)/1000/60
            }

            return null
        },
        Presenter: function(){
            let speakers = this.speakers
            let speaker_id = this.$attrs.value.speakers[0]
            let presenters = speakers.filter((speaker)=>{return speaker.id==speaker_id})
            return presenters[0]
        }
    },
    watch: {
        model: {
            deep: true,
            handler: function(){
                this.$emit('input',this.model)
            }
        },
        form: {
            deep: true,
            handler: function(){
                let start = this.form.start
                let end = this.form.end
                let t1 = new Date(start.date+' '+start.time)
                let t2 = new Date(end.date+' '+end.time)
                this.$attrs.value.start_time = t1.getTime()
                this.$attrs.value.end_time = t2.getTime()
            }
        }
    }
}
</script>

<style scoped lang="scss">
.disabled{
    opacity: 0.4;
}
</style>