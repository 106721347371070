<template>
    <v-card class="speaker-card">
        <div class="speaker-card-col">
            <v-avatar size="120">
                <v-icon v-if="speaker.image.type=='icon'" large>{{speaker.image.value}}</v-icon>
                <img v-if="speaker.image.type=='img'" :src="images.speakers[speaker.image.value]"/>
            </v-avatar>
        </div>
        <div class="speaker-card-col">
            <div class="speaker-name" v-html="speaker.given_name +' '+speaker.family_name"/>
            <div class="speaker-credentials" v-html="speaker.credentials[language]"/>
            <div class="speaker-details" v-html="speaker.details[language]"/>
        </div>
    </v-card>  
</template>

<script>
import speaker_1 from '../assets/speaker_1.png'
import speaker_2 from '../assets/speaker_2.png'
export default {
    props: {
        speaker: {
            type: Object,
            required: true
        },
        language: {
            type: String,
            required: true
        }
    },
    computed: {
        images: function(){
            return {
                speakers: {
                    speaker_1,
                    speaker_2
                }
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.speaker-card{
    display: flex;
    column-gap: 3px !important;
}

.speaker-card-col{
    align-self: center;
}
.speaker-card-col:first-child{
    flex-grow: 0;
    // border: 1px solid red;
}
.speaker-card-col:nth-child(2){
    flex-grow: 2;
    text-align: center;
    // border: 1px solid green;
}

.speaker-name{
    font-size: 1.3em;
    font-weight: bold; 
}

.speaker-credentials{
    font-size: 1.2em;
    margin: 2px;
}

.speaker-details{
    font-size: 0.7em;
}

    .v-avatar{
        border: 5px solid $primary-light-1;
        background-color: $primary-light-4 !important;
        color: white;
        margin: 0px 15px 0px 15px;
    }
</style>