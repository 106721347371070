<template>
<v-card class="mb-1">   
    <v-card-text>
        <v-list-item dense class="pa-0">
            <v-list-item-content style="align-self: baseline;" class="pt-0">
                <div class="d-flex">
                    <v-list-item-title>Name: {{user.given_name}} {{user.family_name}}</v-list-item-title>
                </div>
                <div :class="$vuetify.breakpoint.mdAndDown ? 'd-block' : 'd-flex'">
                    <v-list-item-title>Email: <span style='font-weight:400;'>{{user.email}}</span></v-list-item-title>
                    <v-list-item-title>Preferred Language: <span style='font-weight:400;'>{{user['custom:language']}}</span></v-list-item-title>
                </div>
                <div :class="$vuetify.breakpoint.mdAndDown ? 'd-block' : 'd-flex'">
                    <v-list-item-title>HubSpot ID: <span style='font-weight:400;'>{{user['custom:hubspot_id']}}</span></v-list-item-title>
                    <v-list-item-title>Enrolled: <span style='font-weight:400;'>{{participant.created}}</span></v-list-item-title>
                </div>
            </v-list-item-content>
            
            <v-list-item-action class="ma-0">
                <v-select style="width:100px;" id="attendance-selector" @change="save" :disabled="!user['custom:hubspot_id']" :items="attendanceItems" v-model="model.attended" outlined dense label="Attended?"></v-select>

            </v-list-item-action>
        </v-list-item>
    </v-card-text> 
</v-card>
</template>

<script>
export default {
    props: {
        participant: {
            type: Object,
            required: true
        },
        zoomParticipantMap: {
            type: Object,
            required: true
        },
        user: {
            type: Object,
            required: true
        }
    },
    methods: {
        save: async function(){
            let self = this
            function save(data){
                return new Promise((resolve, reject)=>{
                    self.sendRequest('patch','/api/event/admin/attendance',data)
                })
            }

            // let response = await save({enrollmentRecord: self.model, hubspot_id: self.user['custom:hubspot_id']})
            let response = await self.sendRequest('patch','/api/event/admin/attendance', {enrollmentRecord: self.model, hubspot_id: self.user['custom:hubspot_id']})
            alert(`Attendee Update: ${response.status==200 ? 'success' : 'failed'}`)
        }
    },
    computed: {
        attendanceItems: function(){
            let output = [
                {
                    text: "No",
                    value: "N"
                },
                {
                    text: "Yes",
                    value: "Y"
                }
            ]

            return output
        },
        model: function(){
            return this.$attrs.value
        },
        zoomMatch: function(){
            let output = []
            let zoomParticipantMap = this.zoomParticipantMap
            let user = this.user

            console.log({
                user
            })
            let given_name = user.given_name && user.given_name.length>0 ? user.given_name.toLowerCase() : ''
            let family_name = user.family_name && user.family_name.length>0 ? user.family_name.toLowerCase() : ''
            let first_initial = given_name.substr(0,1)
            let last_initial = family_name.substr(0,1)
            let var_1 = `${first_initial}${family_name}`

            let tests = [
                given_name,
                family_name,
                var_1
            ]

            for(let user_id in zoomParticipantMap){
                let zoomParticipant = zoomParticipantMap[user_id]
                console.log({
                    zoomParticipant,
                    name: `${user.given_name} ${user.family_name}`,
                    user_name: zoomParticipant.user_name,
                    tests
                })
            }

            return output
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep .v-label {
font-size: 13px !important;
}
</style>