function controller(component, languages){
    function assignID(array){
        return new Promise((resolve, reject)=>{
            setTimeout(function(){
                let ids = []
                for(let i=0; i<array.length; i++){
                    let item = array[i]
                    ids.push(item.id)
                }
        
                ids.sort()
        
                for(let i=0; i<ids.length; i++){
                    let id = ids[i]
                    if((i+1)!=id){
                        resolve(i)
                    }
                }
                resolve(ids.length+1)
    
            }, 1000)
        })
    }

    function speakerSource(){
        let self = this
        let template = {
            speaker: function(){
                let output = {
                    id: null,
                    given_name: null,
                    family_name: null,
                    image: {
                        type: 'icon',
                        value: 'mdi-robot-happy-outline'
                    },
                    credentials: {},
                    details: {},
                    disclosures: {}
                }

                for(let i=0; i<languages.length; i++){
                    let language = languages[i]
                    output.credentials[language] = null
                    output.details[language] = null
                    output.disclosures[language] = null
                }

                return output
            }
        }

        this.test = {
            new: async function(records){
                console.log(' speaker > test > new',records)
                for(let i=0; i<records.length; i++){
                    self.new()
                    let record = records[i]
                    for(let field in record){
                        component.selection.speaker[field] = record[field]
                    }
                    await self.save(component.selection.speaker)
                }
            }
        }

        this.new = function(){
            let speakers = component.speakers
            let speaker = template.speaker()
            speakers.push(speaker)
            component.selectSpeaker(speaker)
        }

        this.save = async function(speaker){
            function save(speaker){
                return new Promise((resolve, reject)=>{
                    let action = speaker.id!=null ? 'update' : 'new'
                    component.sendRequest(action=='new' ? 'post' : 'patch','/api/speaker/admin/'+action,speaker).then((response)=>{
                        if(action=='new'){
                            speaker.id = response.data.id
                        }
                        resolve(response)
                    })
                })
            }
        
            let response = await save(speaker)
            component.selection.speaker = null
            component.ui.snackbar.text = "Saved"
            component.ui.snackbar.display = true
        }
    }

    function programSource(){
        let self = this
        let templates = {
            program: function(){
                let output = {
                    id: null,
                    title: {},
                    description: {},
                    events: []
                }

                for(let i=0; i<languages.length; i++){
                    let language = languages[i]
                    output.title[language] = null
                    output.description[language] = null
                }
                return output
            }
        }

        this.new = async function(){
            let programs = component.programs
            let program = templates.program()
            component.programs.push(program)
            component.selectProgram(program)
        }

        this.save = async function(program){
            function save(program){
                return new Promise((resolve, reject)=>{
                    let action = program.id!=null ? 'update' : 'new'
                    component.sendRequest(action=='new' ? 'post' : 'patch','/api/program/save/'+action,program).then((response)=>{
                        if(action=='new'){
                            program.id = response.data.id
                        }
                        resolve(response)
                    })
                })
            }
        
            let response = await save(program)
            component.ui.snackbar.text = "Saved"
            component.ui.snackbar.display = true
        }
    }

    function eventSource(){
        let self = this
        let templates = {
            event: function(){
                let output = {
                    id: null,
                    program_id: null,
                    title: {},
                    introduction: {},
                    agenda: {},
                    learningObjectives: {},
                    meetingLink: null,
                    preEventQuestions: null,
                    postEventQuestions: 1,
                    start_time: null,
                    end_time: null,
                    language: null,
                    speakers: [],
                    publish: 'N',
                    cancelled: 'N',
                    icon: {
                        type: 'icon',
                        value: 'mdi-tooth'
                    },
                    seats: {
                        min: {
                            value: 0,
                            message: {
                                en: "Minimum seats",
                                fr: ""
                            }
                        },
                        max: {
                            value: 0,
                            message: {
                                en: "Fully booked",
                                fr: ""
                            }
                        },
                        warning: {
                            value: 0,
                            message: {
                                en: "Almost fully booked",
                                fr: ""
                            }
                        }
                    }
                }

                for(let i=0; i<languages.length; i++){
                    let language = languages[i]
                    output.title[language] = null
                    output.introduction[language] = null
                    output.learningObjectives[language] = null
                    output.agenda[language] = null
                }
                return output
            }
        }

        this.templates = function(){
            return templates
        }

        this.test = {            
            new: async function(records){
                for(let i=0; i<records.length; i++){
                    let record = records[i]
                    await self.new(component.selection.program)
                    for(let field in record){
                        if(!['program_id'].includes(field)){
                            component.selection.event[field] = record[field]                        
                        }
                    }
                    await self.save(component.selection.event)                    
                    component.cancel('event')
                }
                component.cancel('program')
                await component.getPrograms()
            }
        }

        this.new = async function(program){            
            let event = templates.event()
            event.program_id = program.id
            program.events.push(event)
            component.selectEvent(event)
        }

        this.save = async function(event){
            function save(event){
                return new Promise((resolve, reject)=>{
                    let action = event.id!=null ? 'update' : 'new'
                    component.sendRequest(action=='new' ? 'post' : 'patch','/api/event/admin/'+action,event).then((response)=>{
                        if(action=='new'){
                            event.id = response.data.id
                        }
                        if(response.data && response.data.zoomMeeting){
                            component.selection.event.zoom = response.data.zoomMeeting
                            component.selection.event.zoom.syncronized = true
                        }                        
                        resolve(response)
                    })
                })
            }
        
            await save(event)
            component.ui.snackbar.text = "Saved"
            component.ui.snackbar.display = true            
        }
    }

    this.speaker = new speakerSource()
    this.program = new programSource()
    this.event = new eventSource()

}

module.exports = controller