<template>
  <div>
      <div v-if="ready">

        <v-tabs
            v-model="ui.tab"
            grow
        >
            <v-tab>Programs/Events</v-tab>
            <v-tab v-if="allowAccess || isHygienist">Speakers</v-tab>
        </v-tabs>

        <!-- <template v-for="language in languages">
            <v-btn :color="ui.language==language ? 'primary' : ''" @click="ui.language=language" :key="'ui_language_select_'+language">{{language}}</v-btn>
        </template> -->

        <v-tabs-items v-model="ui.tab">
            <v-tab-item>
                <v-stepper v-model="ui.step" flat>
                    <v-stepper-header v-if="allowAccess" v-show="false">
                        <v-stepper-step step="1">
                            Select Program
                        </v-stepper-step>
                        <v-stepper-step step="2">
                            Program
                        </v-stepper-step>
                        <v-stepper-step step="3">
                            Event Editor
                        </v-stepper-step>
                    </v-stepper-header>

                    <v-stepper-items>
                        <v-stepper-content v-if="allowAccess" v-show="false" step="1">

                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-btn text v-if="!selection.program" @click="controller.program.new"><v-icon>mdi-plus</v-icon> program</v-btn>
                                        <v-list dense>
                                            <template v-for="(program, programIndex) in programs">
                                                <v-list-item :key="'program_select_'+programIndex" :class="[{['selected']:selection.program && selection.program.id==program.id}]">
                                                    <v-list-item-content>
                                                        {{program.title[ui.language]}}
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-btn @click="selectProgram(program)">select</v-btn>
                                                    </v-list-item-action>
                                                </v-list-item>
                                            </template>
                                        </v-list>
                                    </v-col>
                                </v-row>
                            </v-container>

                        </v-stepper-content>



                        <v-stepper-content step="2">
                            <template v-if="selection.program">
                                <ProgramForm  v-if="allowAccess"  v-show="false" @cancel="cancel('program')" @save="save('program')" v-model="selection.program" :language="ui.language" :languages="languages"/>
                                <v-container v-if="selection.program.id" style="border-top: 1px solid grey;margin-top: 25px;">
                                    <v-row>
                                        <v-col>
                                            <v-card>
                                            <v-card-title>
                                                Events
                                            </v-card-title>

                                                <v-card-actions>
                                                    <v-btn text color="success" v-if="selection.program && selection.program.id" @click="controller.event.new(selection.program)">
                                                        <v-icon>mdi-plus</v-icon> Event
                                                    </v-btn>
                                                    <v-btn text color="warning" v-if="allowTestAccess" @click="controller.event.test.new(testData.event)">
                                                        <v-icon>mdi-bug</v-icon> Test
                                                    </v-btn>
                                                </v-card-actions>

                                                <!-- <v-card-text> -->
                                                    <v-list dense v-if="!ui.busy">
                                                        <v-list-item-group v-model="ui.eventList">
                                                        <template v-for="event in sorted(selection.program.events)">
                                                            <v-list-item :key="'event_select_'+event.id" :class="[{['selected']:selection.event && selection.event.id==event.id}]">
                                                                <v-list-item-avatar class="flex-column" style="height: auto;">
                                                                    <v-icon :title="event.zoom && event.zoom.syncronized===true ? 'Event zoom syncronized' 
                                                                            : event.zoom && event.zoom.syncronized=='N/A' ? 'Event zoom syncronized N/A' 
                                                                            : $store.getters.zoomIntegrationTyp==='manual' ? 'Zoom Manual Integration - sync N/A'
                                                                            : null">
                                                                        {{event.zoom && event.zoom.syncronized===true ? 'mdi-check' 
                                                                            : event.zoom && event.zoom.syncronized=='N/A' ? 'mdi-history'
                                                                            : $store.getters.zoomIntegrationTyp==='manual' ? 'mdi-wrench-cog-outline'
                                                                            :'mdi-close'}}
                                                                    </v-icon>
                                                                    <v-icon v-if="event.cancelled==='Y'" title="Event cancelled">mdi-cancel</v-icon>
                                                                </v-list-item-avatar>
                                                                <v-list-item-content>
                                                                    <v-list-item-title>
                                                                        <span v-if="debug" class="debug">ID: {{event.id}}</span> {{event.title[ui.language]}}
                                                                    </v-list-item-title>
                                                                    <div class="d-flex">
                                                                        <v-list-item-subtitle>Starts: {{showStarts(event.start_time)}}</v-list-item-subtitle>
                                                                        <v-list-item-title>Event language: {{event.language}}</v-list-item-title>
                                                                    </div>
                                                                    <v-list-item>Enrolled: {{bookings[event.id]}}</v-list-item>
                                                                </v-list-item-content>
                                                                <v-list-item-action>
                                                                    <template v-if="isPast(event.end_time)">
                                                                    <v-btn v-if="event.zoom!==undefined" icon small link  :href="event.zoom.start_url" target="_blank">
                                                                        <v-icon>mdi-presentation-play</v-icon>
                                                                    </v-btn>
                                                                    <v-btn v-else icon small link> </v-btn>
                                                                    </template>
                                                                    <v-btn icon small @click="selectEvent(event)">
                                                                        <v-icon>mdi-pencil</v-icon>
                                                                    </v-btn>
                                                                </v-list-item-action>
                                                            </v-list-item>
                                                        </template>
                                                        </v-list-item-group>
                                                    </v-list>

                                                <!-- </v-card-text> -->
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </v-container>

                            </template>

                        </v-stepper-content>



                        <v-stepper-content step="3">
                             <v-btn text @click="cancel('event')"><v-icon>mdi-chevron-left</v-icon>Back</v-btn>
                            <EventForm @importZoom="importZoom" @absorbZoomDetails="absorbZoomDetails" :busy="ui.busy" @cancel="cancel('event')" @save="save('event')" v-if="ui.step==3 && selection.event" v-model="selection.event" :speakers="speakers" :language="ui.language" :languages="languages" :allowSpeakerSelection="allowAccess || isHygienist"/>
                        </v-stepper-content>
                    </v-stepper-items>

                </v-stepper>
            </v-tab-item>

            <v-tab-item>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-btn fab x-small color="success" v-if="!selection.speaker" @click="controller.speaker.new"><v-icon>mdi-plus</v-icon></v-btn>
                            <v-btn fab x-small color="warning" v-if="allowTestAccess" @click="controller.speaker.test.new(testData.speakers)"><v-icon>mdi-bug</v-icon></v-btn>

                            <template v-if="selection.speaker">
                                <SpeakerForm v-model="selection.speaker" @cancel="cancel('speaker')" @save="save('speaker')" :language="ui.language"/>
                            </template>
                            <v-container v-else dense>
                                <template v-for="(speaker, speakerIndex) in speakers">
                                    <v-row :key="'speaker_select_'+speakerIndex" :class="[{['selected']:selection.speaker && selection.speaker.id==speaker.id}]">
                                        <v-col cols="8">
                                            <!-- {{speaker.given_name}} {{speaker.family_name}} -->
                                            <SpeakerCard style="width:100%;" :speaker="speaker" :language="ui.language"/>
                                        </v-col>
                                        <v-col cols="4">
                                            <v-btn @click="selectSpeaker(speaker)">select</v-btn>
                                        </v-col>
                                    </v-row>
                                </template>
                            </v-container>

                        </v-col>
                    </v-row>
                </v-container>
            </v-tab-item>
        </v-tabs-items>






      </div>
      <div v-else>
          <Loader/>
      </div>

      <v-snackbar
        v-model="ui.snackbar.display"
        :multi-line="ui.snackbar.multiline"
      >
        {{ui.snackbar.text}}
      </v-snackbar>
  </div>
</template>

<script>
import controllerSource from "./lib/controller.js"
import Loader from "@/components/Loader.vue"
import ProgramForm from "./lib/programForm.vue"
import EventForm from "./lib/eventForm.vue"
import SpeakerForm from "./lib/speakerForm.vue"
import SpeakerCard from '@/components/Events/lib/SpeakerCard.vue'
import speakerData from './lib/testData/speakers.json'
let tools = {
    getRandomInt: function (max) {
        return Math.floor(Math.random() * max);
    }
}

export default {
    components: {
        Loader,
        ProgramForm,
        EventForm,
        SpeakerCard,
        SpeakerForm
    },
    created: function(){
        this.ready = false
        this.init()
    },
    activated: function(){
        this.ready = false
        this.init()
    },
    data: function(){
        return {
            ready: false,
            ui: {
                busy: false,
                eventList: null,
                tab: 0,
                step: 1,
                language: null,
                snackbar: {
                    display: false,
                    multiline: true,
                    text: null
                }
            },
            config: {},
            programs: null,
            bookings: null,
            speakers: null,
            controller: null,
            selection: {
                program: null,
                event: null,
                speaker: null
            },
            zoom: {
                integration: this.$store.getters.zoomIntegrationTyp!=='manual'
            }
        }
    },
    methods: {
        test: async function(){
            let self = this
            self.sendRequest('get','/api/test').then((response)=>{
                console.log('test',response)
            })
        },
        init: async function(){
            this.ui.language = this.$store.getters.language
            await this.getPrograms()
            this.controller = new controllerSource(this, this.languages)
            this.ready = true
            this.selectProgram(this.programs[0])
        },
        getPrograms: async function(){
            let self = this
            function getPrograms(){
                return new Promise((resolve, reject)=>{
                    self.sendRequest('get','/api/event/admin/getPrograms').then((response)=>{
                        resolve(response.data)
                    },(error)=>{
                        reject(error)
                    })
                })
            }
            function getSpeakers(){
                return new Promise((resolve, reject)=>{
                    self.sendRequest('get','/api/getSpeakers').then((response)=>{
                        resolve(response.data)
                    },(error)=>{
                        reject(error)
                    })
                })
            }

            function getBookings(){
                return new Promise((resolve, reject)=>{
                    self.sendRequest('get','/api/event/bookings').then((response)=>{
                        resolve(response.data)
                    })
                })
            }

            this.programs = await getPrograms()
            this.speakers = await getSpeakers()
            this.bookings = await getBookings()
        },
        selectSpeaker: function(speaker){
            this.selection.speaker = speaker
        },
        selectProgram: function(program){
            this.selection.program = program
            this.ui.step++
        },
        selectEvent: function(event){
            this.selection.event = event
            this.ui.step++
        },
        save: async function(target){
            let selection = this.selection
            
            this.languages.forEach((element) => {
                if (element!==selection.event.language){
                    selection.event.title[element] = selection.event.title[selection.event.language];
                }
            })

            this.ui.busy = true
            switch(target){
                case"speaker":
                    await this.controller.speaker.save(selection.speaker)
                break;

                case"program":
                    await this.controller.program.save(selection.program)
                    await this.getPrograms()
                break;

                case"event":
                    if (selection.event.speakers.length==0){
                        this.ui.snackbar.text = 'Can not save event with no appointed speakers'
                        this.ui.snackbar.display = true
                    }
                    else if (!selection.event.title[selection.event.language] || selection.event.title[selection.event.language].length==0){
                        this.ui.snackbar.text = 'Can not save event with blank title'
                        this.ui.snackbar.display = true
                    }
                    else if ((selection.event.zoom===undefined || selection.event.zoom===null) && this.$store.getters.zoomIntegrationTyp==='manual'){
                        this.ui.snackbar.text = 'Can not save event. Zoom Meeting Details not provided.'
                        this.ui.snackbar.display = true                        
                    }
                    else {
                        selection.event.zoom_integration_typ = this.$store.getters.zoomIntegrationTyp
                        await this.controller.event.save(selection.event)
                        await this.getPrograms()
                        this.selectProgram(this.programs[0])
                        for(let i=0; i<selection.program.events.length; i++){
                            if(selection.program.events[i].id == target.id){
                                this.selectEvent(selection.program.events[i])
                            }
                        }
                        this.ui.step=3
                    }

                break;
            }
            this.$forceUpdate()
            this.ui.busy = false
        },
        cancel: function(target){
            let selection = this.selection
            switch(target){
                case"speaker":
                    let speaker = selection.speaker
                    if(!speaker.id){
                        this.speakers.pop()
                    }
                    selection.speaker = null
                break;
                case"program":
                    let program = selection.program
                    if(!program.id){
                        this.programs.pop()
                    }
                    selection.program = null
                    this.ui.step--
                break;
                case"event":
                    let event = selection.event
                    if(!event.id){
                        selection.program.events.pop()
                    }
                    selection.event = null
                    this.ui.step--
                break;
            }
        },
        importZoom: async function(data){
            let response
            try{
                response = await this.sendRequest('get',`/api/zoom/meeting/import/${data.zoom_id}`)
                if(response.data && response.data.zoomMeeting != null){
                    this.selection.event.zoom = response.data.zoomMeeting
                }else{
                    this.ui.snackbar.text = 'Zoom meeting not found: '+data.zoom_id
                    this.ui.snackbar.display = true
                }
            }catch(err){
                this.ui.snackbar.text = 'Zoom meeting not found: '+data.zoom_id
                this.ui.snackbar.display = true
            }
        },
        absorbZoomDetails: function(data){
            let zoom = {id: data.zoom_id, join_url: data.zoom_join_url, password: data.zoom_password}
            this.selection.event.zoom = zoom
            this.ui.snackbar.text = 'Zoom data processed'
            this.ui.snackbar.display = true
        },
        showStarts: function(timestamp){
            let t = new Date(timestamp)
            return t.toString()
        },
        sorted: function(events){
            let output = JSON.stringify(events)
            output = JSON.parse(output)
            output.sort((a, b) => {
                let t1 = a.start_time,
                    t2 = b.start_time;

                if (t1 > t2) {
                    return -1;
                }
                if (t1 < t2) {
                    return 1;
                }
                return 0;
            });

            return output
        },
        isPast: function(timestamp){
            let now = new Date()
            let ends = new Date(timestamp)
            return now<=ends
        }

    },
    computed: {
        debug: function(){
            return this.$store.getters.debug
        },
        languages: function(){
            return this.$store.getters.languages
        },
        testData: function(){
            let controller = this.controller
            let speakers = this.speakers

            function eventData(language){
                let now = new Date()
                let templates = controller.event.templates()
                let output = []

                let records = [
                    {title: '+48hr', seconds: (24*60*60)*(2), language: 'fr-ca', postEventQuestions: 1, publish: 'Y'},
                    {title: '+24hr', seconds: (24*60*60)*(1), language: 'fr-ca', postEventQuestions: 1, publish: 'Y'},
                    {title: '+59min', seconds: (59*60)*(1), language: 'fr-ca', postEventQuestions: 1, publish: 'Y'},
                    {title: '+8Days', seconds: (24*60*60)*(8), language, postEventQuestions: 1, publish: 'Y'},
                    {title: '-30min', seconds: (30*60)*(-1), language, postEventQuestions: 1, publish: 'Y'},
                    {title: '-30sec', seconds: (30)*(-1), language, postEventQuestions: 1, publish: 'Y'},
                    {title: '+48hr', seconds: (24*60*60)*(2), language, postEventQuestions: 1, publish: 'Y'},
                    {title: '+24hr', seconds: (24*60*60)*(1), language, postEventQuestions: 1, publish: 'Y'},
                    {title: '+30min', seconds: (30*60)*(1), language, postEventQuestions: 1, publish: 'Y'},
                    {title: '+30sec', seconds: (30)*(1), language, postEventQuestions: 1, publish: 'Y'},
                ]

                for(let i=0; i<records.length; i++){
                    let data = records[i]
                    let record = templates.event()
                    record.start_time = new Date(now.getTime()+(data.seconds*1000))
                    record.start_time = record.start_time.getTime()
                    record.end_time = new Date((now.getTime()+(data.seconds*1000))+(60*60*1000))
                    record.end_time = record.end_time.getTime()
                    record.title[data.language] = `${data.language} - ${now.toLocaleDateString()} ${now.toLocaleTimeString()}: ${data.title} [auto-gen: ${i+1}]`
                    record.language = data.language
                    record.publish = data.publish
                    record.postEventQuestions = data.postEventQuestions
                    record.speakers.push(speakers[tools.getRandomInt(speakers.length)].id)
                    output.push(record)
                }

                return output
            }

            return {
                speakers: speakerData,
                event: this.ready && this.speakers.length>0 ? eventData(this.$store.getters.language) : []
            }
        },
        allowAccess: function(){
            let groups = this.$store.getters.user && this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups'] ? this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups'] : []
            let allow = ['SuperAdmin','staff','developer','tester']
            for(let i=0; i<groups.length; i++){
                let group = groups[i]
                if(allow.includes(group)){
                    return true
                }
            }
            return false
        },
        isHygienist: function(){
            let groups = this.$store.getters.user && this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups'] ? this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups'] : []
            let allow = ['hygienist']
            for(let i=0; i<groups.length; i++){
                let group = groups[i]
                if(allow.includes(group)){
                    return true
                }
            }
            return false
        },
        allowTestAccess: function(){
            return this.selection.program && this.selection.program.id && this.$store.getters.user.signInUserSession.accessToken.payload['cognito:groups'].includes('SuperAdmin')
        }
    }
}
</script>

<style lang="scss" scoped>
.selected{
    background-color: lightgrey;
}
</style>
