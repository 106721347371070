<template>
<v-card class="loader">
    <v-progress-circular
        class="spinner"
        large
      indeterminate
      color="primary"
    ></v-progress-circular>
</v-card>
</template>

<script>
export default {

}
</script>

<style scoped lang="scss">
.loader{
    margin: 100px auto;
    width: 160px;
    height: 160px;
    border: 1px solid lightgrey;
    border-radius: 12px;
    position: relative;
}

.spinner{
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
}
</style>