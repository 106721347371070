<template>
  <div>
    <v-card flat>
        <v-card-text>
            <v-text-field v-model="$attrs.value.title[language]" label="Program Title" outlined/>
            <v-textarea label="Description" v-model="$attrs.value.description[language]" outlined/>
        </v-card-text>

        <v-card-actions>
            <v-btn text @click="$emit('cancel',model)"><v-icon>mdi-chevron-left</v-icon>Back</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="$emit('save',model)">Save</v-btn>
        </v-card-actions>
    </v-card>
  </div>
</template>

<script>
export default {
    props: {
        language: {
            type: String,
            required: true
        },
        languages: {
            type: Array,
            required: true
        }
    },
    created: function(){
        this.init()
    },
    data: function(){
        return {
            ui: {
                language: 'en'
            }
        }
    },
    methods: {
        init: function(){
            console.log('program form init',this.$attrs.value)
        }
    },
    computed: {
        model: function(){
            return this.$attrs.value
        }
    },
    watch: {
        model: {
            deep: true,
            handler: function(){
                this.$emit('input',this.model)
            }
        }
    }
}
</script>

<style>

</style>